<template>
    <div class="transition-all duration-500 flex flex-col" v-bind:class="{
        'mt-3 mb-px' : !noMargin,
        'visible opacity-100' : elementActive,
        'invisible opacity-25' : !elementActive,
    }">
        <slot name="label">
            <label v-if="label" class="block mb-2">
                {{label}}
                <span class="inline-block text-gray-400" v-if="required">*</span>
            </label>
        </slot>
        <div class="relative grow">
            <textarea 
                @input="handleInput" :value="modelValue"
                :type="type" :placeholder="placeholder" :disabled="disabled" :autofocus="autofocus" :readonly="readonly"
                class="userdata appearance-none border shadow transition-all duration-500 w-full h-full min-h-[7rem] py-3 px-5 text-gray-700 dark:text-white dark:bg-gray-600 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                v-bind:class="{ 
                    'border-gray-100 dark:border-gray-300 shadow-none': !elementActive && !error.show,
                    'border-gray-300 dark:border-gray-500 ': elementActive && !error.show,

                    'rounded' : !first && !second,
                    'rounded-l' : first,
                    'rounded-r' : second,


                    'border-red-700 dark:border-red-400 shadow-red-400 dark:shadow-orange-800' : error.show,
                }"></textarea>
            <div class="absolute top-1.5 right-1.5">
                <LazyElementsHelpBubble v-if="path" :path="path" />
            </div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    type: { type: String, default: 'text' },
    path: { type: String, default: '' },
    label: { type: String },
    modelValue: { type: String },
    placeholder: { type: String },
    disabled: { type: String },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    autofocus: { type: String },
    noMargin: { type: Boolean, default: false },
    first: { type: Boolean, default: false },
    second: { type: Boolean, default: false },
    validation: { type: Object, default: { autovalidate: () => {}, add: () => {}, remove: () => {} } },
})
const emit = defineEmits(['update:modelValue', 'error'])

const error = reactive({
    show: false,
    message: '',
})
const handleInput = (e) => {
    emit('update:modelValue', e.target.value)
    props.validation.autovalidate()
}

const requiredValidation = () => useHandleValidation(props.modelValue?.length > 0, 'Dieses Feld ist erforderlich', error, emit)

const elementActive = ref(false)
onMounted(function(){
    setTimeout(function(){ elementActive.value = true }, 100)
    if(props.required) props.validation.add(props.path, requiredValidation)
})
onBeforeUnmount(() => elementActive.value = false )
onUnmounted(function(){
    if(props.validation) props.validation.remove(props.path)
})
</script>